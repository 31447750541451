import React from "react";
import Layout from "../components/layout";
import ContenidoNosotros from '../components/contenidoNosotros';
const IndexPage = props => (
  <Layout>
    <ContenidoNosotros/>
  </Layout>
)

export default IndexPage
