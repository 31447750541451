import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import {css} from '@emotion/core';
import Fade from 'react-reveal/Fade';



const ContenidoNosotros = () => {
    const resultado = useStaticQuery(graphql`
    query {
        allDatoCmsNosotro(filter: { slug: { eq: "nosotros" } }) {
        nodes {titulo
            contenido
            descripcion2
            descripcion3
           
                                  
            
                  }
              }  
          }
      `)


    const { titulo, contenido , descripcion2 , descripcion3} = resultado.allDatoCmsNosotro.nodes[0]       
    return ( 
    <> 
    <Fade top>
 <h2
        css={css`
          margin-top: 4rem;
          text-align: center;
          font-size: 4rem;
          padding: 2rem;
        `}
      >
        {titulo}
      </h2>  
      </Fade>
      
      <Fade right>
      <div css={css` padding: 2px 16px;
          margin-bottom: 2rem;
          font-size:2rem;
          box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);`}> 
      <p  css={css`
          font-size: 2.3rem;
          padding: 4rem;
          margin: auto;
          width: 75%;
          
          
        `}> {contenido} </p>

      </div>
    </Fade>
    <Fade left>
    <div css={css` padding: 2px 16px;
              margin-bottom: 2rem;
              font-size:2rem;
              box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);`}> 
            <p  css={css`
              font-size: 2.6rem;
              padding: 4rem;
              margin: auto;
              width: 75%;
            `}> {descripcion2} </p>
            </div>
            </Fade>
            <Fade right>
            <div css={css` padding: 2px 16px;
              margin-bottom: 2rem;
              font-size:2rem;
              box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);`}> 
            <p  css={css`
              font-size: 2.6rem;
              padding: 4rem;
              margin: auto;
              width: 75%;
            `}> {descripcion3} </p>
            </div>
            </Fade>

      <Fade center>
      <div css={css `text-align:center;`}>
       <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3760.4233971942044!2d-99.16495669999999!3d19.523428!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xc907d6c1f2c54d32!2sSoluciones%20Tecnologicas%20Monarca!5e0!3m2!1ses-419!2smx!4v1603393034437!5m2!1ses-419!2smx" 
       width="45%" height="320" css={css`border:0;`} frameborder="3"  allowfullscreen="" aria-hidden="false" tabindex="0"/> <br></br></div>
      </Fade>

      
      </>);
}
 
export default ContenidoNosotros;